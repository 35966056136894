class API {
  constructor() {
    this.API_URL = "https://" + process.env.VUE_APP_API_HOST;
  }

  async getCompanySite() {
    const site = await window.localStorage.getItem("site");
    const company = await window.localStorage.getItem("company");
    return "/" + company + "/" + site;
  }

  async getUrl(url = "", queryString = "", includeCompany = false) {
    this.token = await window.localStorage.getItem("token");
    this.companyString = await this.getCompanySite();
    let rawUrl = url + (includeCompany ? this.companyString : "") + queryString;
    const response = await fetch(this.API_URL + rawUrl, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }

  async get(url = "") {
    this.token = await window.localStorage.getItem("token");
    const response = await fetch(this.API_URL + url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }

  async post(url = "", data = {}) {
    this.token = await window.localStorage.getItem("token");
    const response = await fetch(this.API_URL + url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    if (response.status != 200 && response.status != 201) {
      throw "Server error: Error code is " + response.status;
    }

    return response.json(); // parses JSON response into native JavaScript objects
  }

  async login(email, password) {
    let result = await api.post("/api/user/login", { user: email, password: password });
    if (result === "user not found") {
      throw { email: "not found" };
    } else if (result === "unauthorized") {
      throw { email: "unauthorized" };
    }
    return result;
  }

  async signup(name, email, password, company) {
    let result = await api.post("/api/user/signup", { name: name, user: email, password: password, company: company });
    return result;
  }

  async add_site(site, company) {
    let result = await api.post("/api/company/add_site/" + company, { site: site });
    return result;
  }

  async validate(token) {
    let result = await api.post("/api/user/validate", { token: token });
    return result;
  }

  async add_company(company, slug, tz, user_email) {
    let result = await api.post("/api/company/add", { company_name: company, timezone: tz, company: slug, user_email: user_email });
    return result;
  }

  async add_device_code(data) {
    let result = await api.post("/api/device/code/add", data);
    return result;
  }

  async add_cogs_doc(company, doc) {
    let result = await api.post("/api/company/cogs/save_doc/" + company, { doc: doc });
    return result;
  }

  async get_stations(company) {
    let result = await api.get("/api/company/get_stations/" + company);
    return result;
  }

  async get_suppliers(company) {
    let result = await api.get("/api/company/cogs/get_suppliers/" + company + "?reduce=true&group_level=2");
    return result.rows;
  }

  async search_suppliers(company, supplier) {
    let result = await api.get("/api/company/cogs/get_suppliers/" + company + "?reduce=false&key=" + supplier);
    return result.rows;
  }

  async search_categories(company, category) {
    let result = await api.get("/api/company/cogs/get_base_recipes/" + company + "?reduce=false&start_key=[" + category + "]&end_key=[" + category + ",{}]");
    console.log(category);
    return result.rows;
  }

  async get_products(company) {
    let result = await api.get("/api/company/cogs/get_products/" + company);
    return result.rows;
  }

  async get_uom(company) {
    let result = await api.get("/api/company/cogs/get_uom/" + company + "?reduce=true&group=true");
    return result.rows;
  }

  async get_base_recipes(company) {
    let result = await api.get("/api/company/cogs/get_base_recipes/" + company + "?reduce=false");
    return result.rows;
  }

  async get_menu_recipes(company) {
    let result = await api.get("/api/company/cogs/get_menu_recipes/" + company + "?reduce=false");
    return result.rows;
  }

  async get_recipe_categories(company) {
    let result = await api.get("/api/company/cogs/get_base_recipes/" + company + "?reduce=true&group_level=1");
    return result.rows;
  }

  async get_pos_categories(company, site) {
    let result = await api.get("/api/categories/get/" + company + "/" + site);
    return result.rows;
  }

  async get_pos_products(company, site) {
    let result = await api.get("/api/products/get/" + company + "/" + site);
    return result.rows;
  }

  async get_sales_data(company, site, startDate, endDate) {
    let result = await api.get("/api/reports/sales/" + company + "_" + site + "?reduce=true&group_level=1&start_key=[\"" + startDate + "\"]&end_key=[\"" + endDate + "\"]");
    return result.rows;
  }

  async get_stacked_data(company, site, startDate, endDate) {
    let result = await api.get("/api/reports/sales/" + company + "_" + site + "?reduce=true&group_level=2&start_key=[\"" + startDate + "\"]&end_key=[\"" + endDate + "\"]");
    return result.rows;
  }

	async get_stacked_count(company, site, startDate, endDate) {
    let result = await api.get("/api/reports/count/" + company + "_" + site + "?reduce=true&group_level=2&start_key=[\"" + startDate + "\"]&end_key=[\"" + endDate + "\"]");
    return result.rows;
  }

  async get_sales_total(company, site, startDate, endDate) {
    let result = await api.get("/api/reports/sales/" + company + "_" + site + "?reduce=true&group_level=0&start_key=[\"" + startDate + "\"]&end_key=[\"" + endDate + "\"]");
    return result.rows;
  }

  async get_sales_timeframe(company, site, days) {
    let result = await api.post("/api/reports/timeframe/sales/" + company + "/" + site, { days: days });
    return result;
  }

  async get_count_timeframe(company, site, days) {
    let result = await api.post("/api/reports/timeframe/count/" + company + "/" + site, { days: days });
    return result;
  }

  async get_product_count(company, site, startDate, endDate) {
    let result = await api.get("/api/reports/count/" + company + "_" + site + "?reduce=true&group_level=1&start_key=[\"" + startDate + "\"]&end_key=[\"" + endDate + "\"]");
    return result.rows;
  }

  async get_count_total(company, site, startDate, endDate) {
    let result = await api.get("/api/reports/count/" + company + "_" + site + "?reduce=true&group_level=0&start_key=[\"" + startDate + "\"]&end_key=[\"" + endDate + "\"]");
    return result.rows;
  }

  async save_company_settings(company, key, value) {
    let result = await api.post("/api/company/settings/" + company, { key: key, value: value });
    return result;
  }

  async getModifierSets(company, site) {
    const result = await api.get("/api/modifiersets/get/" + company + "/" + site + "?include_docs=false");
    return result.rows;
  }
  
}

const api = new API();
export { api };
