<template>
  <div class="prods-edit">
    <button v-if="!recipeAltered" @click="addNewRecipe">Add Recipe</button>
    <button v-if="recipeAltered" @click="saveNewRecipe">Save Recipe</button>
    <div class="totals">
      <a href="/" @click.stop.prevent="clearForm" id="left">Clear Form</a>
      Total: ${{ costing[4].toFixed(2) }}
      <p v-if="currRecipe.value.cost_per_serving != null">
        Cost per serving: {{ cost_per_serving }}
      </p>
    </div>
    <form @submit.prevent.stop="">
      <label for="recipe">Name</label>
      <input type="text" name="recipe" v-model="currRecipe.value.escaped" />
      <label for="category">Category</label>
      <input list="category" name="category" v-model="currRecipe.value.name" />
      <datalist id="category">
        <option
          v-for="category in cogsRecipeCategories"
          v-bind:key="category"
          >{{ category.key[0] }}</option
        >
      </datalist>
      <div v-if="recipeType == 'prep'" class="unit-serving">
        <div id="cost-title">
          <div class="left-align unit">
            <div class="radio-container">
              <input
                type="radio"
                id="small-radio"
                value="uom"
                v-model="currRecipe.value.costing_type"
              />
            </div>
            <div class="unit-block">
              <label for="uom" class="one-label">UOM </label>
              <input list="uoms" name="uom" v-model="currRecipe.value.UOM" />
              <datalist id="uoms">
                <option v-for="unit in cogsUOM" v-bind:key="unit.key">{{
                  unit.key
                }}</option>
              </datalist>
            </div>
            <div class="unit-block">
              <label for="quantity" class="one-label">Quantity </label>
              <input
                type="number"
                name="quantity"
                v-model="currRecipe.value.uom_quantity"
              />
            </div>
          </div>
          <span id="cost"> OR </span>
          <div class="right-align unit">
            <div class="radio-container">
              <input
                type="radio"
                id="small-radio"
                value="servings"
                v-model="currRecipe.value.costing_type"
              />
            </div>
            <div class="unit-block">
              <label for="uom" class="one-label"># servings </label>
              <input
                list="uoms"
                name="uom"
                v-model="currRecipe.value.servings"
              />
            </div>
          </div>
        </div>
      </div>
      <div id="cost-title">
        <label for="type">Recipe Ingredients</label
        ><span id="cost">Ingredients cost: ${{ costing[1].toFixed(2) }}</span>
      </div>
      <div>
        <button
          v-for="(ingredient, idx) in currRecipe.value.ingredients"
          :key="ingredient"
          class="ingredient-btn"
        >
          <font-awesome-icon
            :icon="['fas', 'xmark']"
            @click="removeIngredient(idx)"
          />
          <div id="recipe-ing">
            <div class="ingredient">
              <p>
                {{ findIngredientByID(ingredient.id).key }} -
                {{ ingredient.usage }}{{ ingredient.uom }}
              </p>
              <p class="subtitle">
                {{ findIngredientByID(ingredient.id).value.name }}
              </p>
            </div>
            <p>${{ costing[0][idx].price.toFixed(2) }}</p>
            <span
              v-if="
                findIngredientByID(ingredient.id).value.price_per_uom.split(
                  ' '
                )[2] !== ingredient.uom
              "
            >
              1
              {{
                findIngredientByID(ingredient.id).value.price_per_uom.split(
                  " "
                )[2]
              }}
              =
              <input
                type="number"
                step="any"
                v-model="ingredient.uom_relationship"
              />{{ ingredient.uom }}
            </span>
          </div>
        </button>
      </div>
      <input
        type="search"
        placeholder="Search ingredients to add..."
        v-model="ingredientsQuery"
      />
      <ul id="add">
        <button
          v-for="ingredient in foundIngredients"
          :key="ingredient"
          class="ingredient-btn extended"
        >
          <a href="/" @click.stop.prevent="loadProduct(ingredient)">{{
            ingredient.key
          }}</a>
          <font-awesome-icon
            :icon="['fas', 'plus']"
            @click="loadIngredient(ingredient)"
          />
          <div>
            <label for="usage">Usage</label>
            <input
              type="number"
              step="any"
              name="usage"
              v-model="ingredient.usage"
            />
            <label for="ing-uom">UOM</label>
            <input list="uoms" name="ing-uom" v-model="ingredient.uom" />
            <datalist id="uoms">
              <option v-for="unit in cogsUOM" v-bind:key="unit.key">{{
                unit.key
              }}</option>
            </datalist>
          </div>
        </button>
      </ul>
      <div id="cost-title">
        <label for="type">Sub Recipes</label
        ><span id="cost">Sub recipes cost: ${{ costing[3].toFixed(2) }}</span>
      </div>
      <div>
        <button
          v-for="(parentRecipe, idx) in currRecipe.value.recipes"
          :key="parentRecipe"
          class="ingredient-btn"
        >
          <font-awesome-icon
            :icon="['fas', 'xmark']"
            @click="removeRecipe(idx)"
          />
          <div id="recipe-ing">
            <p>
              {{ findRecipeByID(parentRecipe.id).key[1] }} -
              {{ parentRecipe.usage }}{{ parentRecipe.uom }}
            </p>
            <p>${{ costing[2][idx].price.toFixed(2) }}</p>
            <span
              v-if="
                findRecipeByID(parentRecipe.id).value.costing_type == 'servings'
              "
              >One serving
            </span>
            <span
              v-else-if="
                findRecipeByID(parentRecipe.id).value.price_per_uom.split(
                  ' '
                )[2] !== parentRecipe.uom
              "
            >
              1
              {{
                findRecipeByID(parentRecipe.id).value.price_per_uom.split(
                  " "
                )[2]
              }}
              =
              <input type="number" v-model="parentRecipe.uom_relationship" />{{
                parentRecipe.uom
              }}
            </span>
            <span
              v-else-if="
                findRecipeByID(parentRecipe.id).value.price_per_uom.split(
                  ' '
                )[2] == parentRecipe.uom
              "
              >{{
                findRecipeByID(parentRecipe.id).value.price_per_uom.split(
                  " "
                )[2]
              }}</span
            >
          </div>
        </button>
      </div>
      <input
        type="search"
        placeholder="Search recipes to add..."
        v-model="subRecipesQuery"
      />
      <ul id="add">
        <button
          v-for="recipe in foundSubRecipes"
          :key="recipe"
          class="ingredient-btn"
        >
          <a href="/" @click.stop.prevent="loadRecipe(recipe)">{{
            recipe.key[1]
          }}</a>
          <font-awesome-icon
            :icon="['fas', 'plus']"
            @click="loadSubRecipe(recipe)"
          />
          <div v-if="recipe.value.costing_type == 'servings'">One Serving</div>
          <div v-else>
            <label for="usage">Usage:</label>
            <input
              type="number"
              step="any"
              name="usage"
              v-model="recipe.usage"
            />
            <label for="ing-uom">UOM:</label>
            <input list="uoms" name="ing-uom" v-model="recipe.uom" />
            <datalist id="uoms">
              <option v-for="unit in cogsUOM" v-bind:key="unit.key">{{
                unit.key
              }}</option>
            </datalist>
          </div>
        </button>
      </ul>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { api } from "@/helpers/api";
import { costingCalc } from "@/helpers/costing";

export default {
  name: "Recipe",
  data() {
    return {
      searchQuery: "",
      ingredientsQuery: "",
      recipesQuery: "",
      foundIngredients: [],
      foundRecipes: [],
      addedIngriedients: [],
      addedBaseRecipes: [],
      subRecipesQuery: "",
      foundSubRecipes: [],
      currRecipe: {
        value: {
          escaped: "",
          price_per_uom: "",
          name: "",
          type: this.recipeType,
          ingredients: [],
          recipes: [],
          UOM: "",
          costing_type: "",
          uom_quantity: 1,
        },
      },
      recipePrice: "",
      recipeUOM: "",
      recipeAltered: false,
    };
  },
  props: {
    currentRecipe: {},
    recipeType: String,
  },

  computed: {
    ...mapGetters({
      cogsSuppliers: "cogsSuppliers",
      cogsProducts: "cogsProducts",
      cogsUOM: "cogsUOM",
      companyName: "companyName",
      cogsSearchProducts: "cogsSearchProducts",
      cogsRecipeCategories: "cogsRecipeCategories",
      cogsBaseRecipes: "cogsBaseRecipes",
      cogsSearchRecipes: "cogsSearchRecipes",
      findIngredientByID: "findIngredientByID",
      findRecipeByID: "findRecipeByID",
    }),
    currRecipePrice() {
      return this.currRecipe.value.price_per_uom.split(" ")[0];
    },
    currRecipeUOM() {
      return this.currRecipe.value.price_per_uom.split(" ").slice(-1)[0];
    },
    costing() {
      // returns costings in an array, all calcs are gst excl
      return costingCalc.cost(this.currRecipe);
    },
    cost_per_serving() {
      return (this.costing[1] / this.currRecipe.value.servings).toFixed(2);
    },
  },

  methods: {
    clearForm() {
      this.currRecipe = {
        value: {
          escaped: "",
          price_per_uom: "",
          name: "",
          type: this.recipeType,
          ingredients: [],
          recipes: [],
          UOM: "",
          servings: null,
        },
      };
      this.recipeAltered = false;
    },

    updateCogsRecipes() {
      this.$store.dispatch("getCogsRecipeCategories");
      this.$store.dispatch("getCogsBaseRecipes");
      this.$store.dispatch("getCogsMenuRecipes");
    },

    addNewRecipe() {
      if (
        !("costing_type" in this.currRecipe.value) ||
        this.currRecipe.value.costing_type == ""
      ) {
        document.getElementById("cost-title").classList.add("border");
        return;
      } else {
        this.currRecipe.value.price_per_uom =
          (this.costing[4] / this.currRecipe.value.uom_quantity).toFixed(2) +
          " per " +
          this.currRecipe.value.UOM;
        this.currRecipe.value.type = this.recipeType;
        api.add_cogs_doc(this.companyName, this.currRecipe);
        this.updateCogsRecipes();
        this.clearForm();
      }
    },

    saveNewRecipe() {
      if (
        this.recipeType == "prep" &&
        (!("costing_type" in this.currRecipe.value) ||
          this.currRecipe.value.costing_type == "")
      ) {
        document.getElementById("cost-title").classList.add("border");
        return;
      } else {
        this.currRecipe.value.price_per_uom =
          (this.costing[4] / this.currRecipe.value.uom_quantity).toFixed(2) +
          " per " +
          this.currRecipe.value.UOM;
        this.currRecipe.value.cost_per_serving =
          this.costing[1] / this.currRecipe.value.servings;
        this.currRecipe.value.cost = this.costing[4];
        api.add_cogs_doc(this.companyName, this.currRecipe);
        this.updateCogsRecipes();
        this.foundIngredients = [];
        this.foundRecipes = [];
        this.foundSubRecipes = [];
        this.recipesQuery = "";
        this.ingredientsQuery = "";
        this.clearForm();
        console.log(this.currRecipe);
      }
    },

    loadIngredient(ingredient) {
      if (!("ingredients" in this.currRecipe.value)) {
        this.currRecipe.value.ingredients = [];
      }
      this.currRecipe.value.ingredients.push({
        id: ingredient.id,
        usage: ingredient.usage,
        uom: ingredient.uom,
      });
    },

    loadSubRecipe(recipe) {
      if (!("recipes" in this.currRecipe.value)) {
        this.currRecipe.value.recipes = [];
      }
      this.currRecipe.value.recipes.push({
        id: recipe.id,
        usage: recipe.usage,
        uom: recipe.uom,
      });
    },

    removeIngredient(idx) {
      this.currRecipe.value.ingredients.splice(idx, 1);
    },

    removeRecipe(idx) {
      this.currRecipe.value.recipes.splice(idx, 1);
    },

    findIngredient() {
      this.foundIngredients = this.cogsProducts.filter((product) => {
        return product.value.escaped
          .toLowerCase()
          .includes(this.ingredientsQuery.toLowerCase());
      });
    },

    findRecipe() {
      this.foundRecipes = this.cogsBaseRecipes.filter((product) => {
        return product.value.escaped
          .toLowerCase()
          .includes(this.recipesQuery.toLowerCase());
      });
    },

    queryCheck(query, list) {
      if (query.length > 1) {
        return list(query);
      } else {
        return [];
      }
    },
  },

  watch: {
    searchQuery: function() {
      this.foundProducts = this.queryCheck(
        this.searchQuery,
        this.cogsSearchProducts
      );
    },

    ingredientsQuery: function() {
      this.foundIngredients = this.queryCheck(
        this.ingredientsQuery,
        this.cogsSearchProducts
      );
    },

    recipesQuery: function() {
      this.foundRecipes = this.queryCheck(
        this.recipesQuery,
        this.cogsSearchRecipes
      );
    },

    subRecipesQuery: function() {
      this.foundSubRecipes = this.queryCheck(
        this.subRecipesQuery,
        this.cogsSearchRecipes
      );
    },

    currentRecipe: function() {
      this.currRecipe = this.currentRecipe;
      this.recipeAltered = true;
      console.log(this.currentRecipe);
    },
  },

  mounted() {},
};
</script>

<style lang="scss">
.prods-edit {
  // display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  max-width: 65%;
  top: 0px;
  margin: 0 auto;
  margin-bottom: 1rem;
  // padding: 2rem;
  flex-grow: 1;
  max-height: 80vh;
  overflow: auto;
  .totals {
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    color: var(--action-colour);
    p {
      margin: 0;
    }
  }
  button {
    width: 100%;
    margin: 0.5rem 0;
    background-color: var(--action-colour);
  }
  #left {
    text-decoration: underline;
  }
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    .border {
      box-shadow: var(--box-shadow);
    }
    #cost-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0.25rem 0.25rem;
      .unit {
        display: flex;
        align-items: center;
        .unit-block {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0.5rem;
          input {
            width: 3rem;
          }
        }
      }

      #small-radio {
        appearance: none;
        margin-right: 0.5rem;
        border-radius: 50%;
        transition: all 500ms ease-in-out;
        height: 1rem;
        width: 1rem;
        white-space: nowrap;
        border: 2px solid var(--secondary-colour);
        &:checked {
          background-color: var(--action-colour);
          border: 5px solid var(--secondary-colour);
        }
      }
      input {
        width: 3rem;
      }
      .one-label {
        margin: 0.5rem;
      }
      #cost {
        color: var(--action-colour);
      }
    }
    input {
      padding: 0.5rem;
      margin: 0.25rem;
      box-shadow: var(--box-shadow);
      border-radius: 5px;
      border: none;
    }
    .ingredient-btn {
      display: flex;
      flex-direction: row;
      width: calc(100% - 15px);
      background: var(--color-primary);
      box-shadow: var(--box-shadow);
      border-radius: 2.5px;
      padding: 0.5rem;
      text-align: left;
      margin: 0.5rem;
      input {
        margin-left: 1rem;
      }
      #recipe-ing {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .ingredient {
          display: flex;
          flex-direction: column;
          margin: 1rem 0.5rem;
          p {
            margin: 0;
          }
          .subtitle {
            font-size: 0.75rem;
            font-weight: lighter;
            margin: 0;
            color: var(--action-colour);
            &:hover {
              color: var(--banner-colour);
            }
          }
        }
        span {
          margin-right: 1rem;
          input {
            width: 65px;
            margin: 0;
            padding: 0.25rem;
          }
        }
      }
      svg {
        width: 20px;
        height: 20px;
        float: right;
        background-color: var(--warning-colour);
        border-radius: 50%;
      }
    }
  }
  #add {
    max-height: 30vh;
    overflow: auto;
    margin: 0;
    padding: 0.25rem;

    > .extended {
      input {
        width: 50px;
        padding: 0.25rem;
        margin: 0.25rem;
      }
    }
    > .ingredient-btn {
      svg {
        background-color: var(--action-colour);
      }
    }
  }
}
@media only screen and (orientation: portrait) {
  .prods-edit {
    min-width: 90%;
    margin: 0 auto;
    padding: 1rem;
    .site-select {
      margin: 0 auto;
    }
    form {
      .unit-serving {
        #cost-title {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          #cost {
            margin-top: 0.5rem;
          }
          .unit {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            .radio-container {
              flex: 0 1 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .unit-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin: 0.5rem;

              input {
                width: 3rem;
              }
            }
          }
          // .right-align {
          //   text-align: right;
          // }
        }
      }
    }
  }
  .prod-list {
    .search {
      ul {
        width: 85%;
      }
    }
  }
}
</style>
