<template>
  <Authenticated>
    <section class="section">
      <h2>Menu: {{ site }}</h2>
      <div
        v-for="category in POSCategories(site)"
        :key="category"
        class="product-grid-container"
      >
        <h3 v-if="category.value.name !== 'Catering'">
          {{ category.value.name }}
        </h3>
        <div v-if="category.value.name !== 'Catering'" class="product-grid">
          <div
            tabindex="0"
            v-for="product in category.value.products"
            :key="product"
            class="product"
            @click.stop.prevent="openModal(product)"
          >
            <p class="none">
              {{ (prod = POSProductLookup({ site: site, prod: product })) }}
            </p>
            <p v-if="prod">{{ prod.value.name }}</p>
            <p v-if="prod">${{ prod.value.price }}</p>
          </div>
          <div class="product" @click.stop.prevent="openModal('addNew')">
            Add New Product
          </div>
        </div>
      </div>
    </section>
    <ProductModal
      :product="product.value"
      :open="showModal"
      :modifierSets="modifiersets"
      @save="saveProduct"
      @remove="removeProduct"
    />
  </Authenticated>
</template>

<script>
import { mapGetters } from "vuex";
import Authenticated from "../_layouts/Authenticated.vue";
import createdMixin from "@/components/_mixins/createdMixin";
import ProductModal from "@/components/Menus/ProductModal";
import { api } from "@/helpers/api";
import Slug from "slug";

export default {
  name: "SiteMenu",
  mixins: [createdMixin],
  components: { Authenticated, ProductModal },
  data() {
    return {
      site: this.$route.params.site,
      product: {},
      showModal: false,
      open: false,
      company: localStorage.getItem("company"),
      modifiersets: {},
      channel: false,
    };
  },

  async mounted() {
    this.modifiersets = await api.getModifierSets(this.company, this.site);

    this.channels.map((channel) => {
      let topic = channel.topic.split(":");
      if (topic[0] == "pos" && topic[2] == this.site) {
        this.channel = channel;
      }
    });
  },

  computed: {
    ...mapGetters({
      POSProducts: "POSProducts",
      POSCategories: "POSCategories",
      POSProductLookup: "POSProductLookup",
      channels: "channels",
    }),
  },
  methods: {
    save() {
      this.channel.push("document:update", this.current);
      //console.log(this.current);
    },

    async removeProduct(product) {
      var foundProductIndex = this.current.products.findIndex((p) => {
        return "product:" + p === product._id;
      });

      if (foundProductIndex != -1) {
        await this.channel.push("document:delete", {
          doc: product._id,
          rev: product._rev,
          category: Slug(this.name),
        });
        this.current.products.splice(foundProductIndex, 1);
        this.save();
      }
    },

    saveProduct(product) {
      console.log(product.name);
      var foundProduct = this.POSProductLookup({
        site: this.site,
        prod: Slug(product.name),
      });
      ////console.log(product.name);
      if (foundProduct) {
        console.log(product);
        foundProduct = product;
        this.channel.push("document:update", product);
      } else {
        product._id = "product:" + Slug(product.name);
        product.category = Slug(this.name);
        this.channel.push("document:new", product);
        ////console.log(product);
        this.current.products.push(Slug(product.name));
        this.save();
      }
    },

    openModal(product) {
      if (product == "addNew") {
        this.product = {
          value: {
            name: null,
            description: null,
            caption: null,
            active: true,
            price: null,
            modifiersets: [],
            images: [],
            soldout: false,
          },
        };
        this.showModal = !this.showModal;
        return;
      } else {
        this.showModal = !this.showModal;
        this.product = this.POSProductLookup({
          site: this.site,
          prod: product,
        });
      }
    },
  },
};
</script>

<style scoped>
.none {
  display: none;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

h2 {
  margin: 3rem 1rem 1rem 1rem;
  text-transform: capitalize;
}

.product-grid-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 1rem auto;
}

.product-grid {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.5rem;
  margin: 1rem;
}
.product {
  height: 5rem;
  width: 10rem;
  border-radius: 5px;
  padding: 1rem;
  /* margin: 0.5rem; */
  background-color: var(--primary-colour);
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  p {
    margin: 0.25rem;
    color: var(--text-colour);
  }
  &:active {
    box-shadow: none;
  }
}

@media only screen and (orientation: portrait) {
  h3,
  h2 {
    width: 50%;
  }

  .product-grid {
    width: 50%;
    grid-template-columns: repeat(2, 0fr);
    grid-auto-flow: row;
    margin: 0.5rem;
  }

  .product {
    width: 8rem;
    height: 3.5rem;
  }
}
</style>
